import { useToast } from 'vue-toastification'
const toast = useToast()

export async function handleGet({ url, errMsg }) {
  const { $api } = useNuxtApp()

  try {
    const response = await $api.get(url)

    return response
  } catch (ex) {
    handleError(ex, errMsg)
  }
}

export async function handlePost({ url, body, errMsg, successMsg }) {
  const { $api } = useNuxtApp()

  try {
    const response = await $api.post(url, body)

    if (successMsg) toast.success(successMsg, { position: 'bottom-right' });

    return response
  } catch (ex) {
    handleError(ex, errMsg)
  }
}

export async function handleDownload({ endpoint, body, errMsg, successMsg }) {
  const { $api } = useNuxtApp()

  try {
    const response = await $api.post(endpoint, body, {
      responseType: 'blob'
    })

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `eip-tembi-data.xlsx`);
    document.body.appendChild(link);
    link.click();

    if (successMsg) toast.success(successMsg, { position: 'bottom-right' });

    return response
  } catch (ex) {
    handleError(ex, errMsg)
  }
}

function handleError(err, message) {
  console.log('CATCHED EXCEPTION', err)

  if (err?.response?.status === 401) {
    window.sessionStorage.setItem('redirectUrl', window.location.pathname)
    return navigateTo('/login')
  }

  useBugsnag().notify(err)

  if (message) {
    toast.error(message, { position: 'bottom-right' })
  }
}